import React from 'react';
import './App.css';
import SignIn from './components/gapi/Gapi.js';
import BGTile from './components/bgtile/BGTile.js';
import Slider from './components/slider/Slider.js';


function App() {
  return (
    <div className="App">
      <header className="App-header Font-rajdhani UserPanel">
        <SignIn/>
        <Slider/>
      </header>
      <BGTile/>
    </div>
  );
}

export default App;
