import React, { Component }from 'react';
//import {gapi} from 'gapi-script';
import GoogleLogin from 'react-google-login';
import Profpic from '../profpic/Profpic.js';
import './Gapi.css';
import logo from '../../pp.png';

const API_KEY = process.env.REACT_APP_API_KEY;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const SCOPE = "profile email openid https://www.googleapis.com/auth/youtube.readonly";
const ENDPOINT = "https://www.googleapis.com/youtube/v3/subscriptions";
const BASIC_PARAM = "part=snippet&mine=true&maxResults=50";

class SignIn extends Component{
    constructor(props){
        super(props);
        this.onSignIn = this.onSignIn.bind(this);
        this.state = {renderButton: true};
    }

    componentDidMount() {
        window.loginbutton = this;
        console.log('this mounted');
        // gapi.signin2.render('g-signin2', {
        //     'scope': SCOPE,
        //     'width': 200,
        //     'height': 50,
        //     'longtitle': true,
        //     'theme': 'dark',
        //     'onsuccess': this.onSignIn
        // }); 
    }

    async onSignIn(googleUser) {
        var profile = googleUser.getBasicProfile();
        console.log('Name: ' + profile.getName());
        var auth = googleUser.getAuthResponse(true);

        window.pfpinstance.updateIm(profile.getImageUrl())

        this.setState({
            name: profile.getName(),
            imurl: profile.getImageUrl(),
            actoken: auth.access_token
        })
        
        var subs, nptoken, totalSubs;
        const request = new Request(`${ENDPOINT}?${BASIC_PARAM}&key=${API_KEY}&access_token=${auth.access_token}`,
        {
            method:'GET'
        });
        await fetch(request).then(function(response) {
            if (!response.ok) {
                throw new Error(`Error lmao: ${response.status}`)
            }
            return response.json()
        }).then(function(data) {
            subs=data.items;
            nptoken=data.nextPageToken;
            totalSubs=data.pageInfo.totalResults;
        })
        console.log(`Found ${totalSubs} entries of subscribed channels (with duplicate). Fetching...`)
        totalSubs -= 50;
        while (totalSubs > 50) {
            const request = new Request(`${ENDPOINT}?${BASIC_PARAM}&key=${API_KEY}&access_token=${auth.access_token}&pageToken=${nptoken}`,
            {
                method:'GET'
            });
            await fetch(request).then(function(response) {
                if (!response.ok) {
                    throw new Error(`Error lmao: ${response.status}`)
                }
                return response.json()
            //eslint-disable-next-line
            }).then(function(data) {
                subs=subs.concat(data.items);
                nptoken=data.nextPageToken;
            })
            console.log(`${totalSubs} channels left to be finished...`)
            totalSubs -=50;
        }

        // const neoSubs = [];
        // subs.forEach(
        //     function(dat) {
        //         if (!(dat in neoSubs)) {
        //             neoSubs.push(dat);
        //         }
        //     }
        // );

        // subs = subs.filter((value, index, self) => self.indexOf(value) === index);

        console.log('finished fetching channels, filtering for duplicates.');

        const subsId = [];
        subs.forEach( function(x) {
            subsId.push(x.snippet.channelId);
        })
        
        var id;
        const subsFilter = subs.filter(function(x, i, arr) {
            id = x.snippet.resourceId.channelId;
            if (subsId.includes(id)) {
                return false
            } else {
                subsId.push(id);
                return true
            }
        })

        this.setState({renderButton: false})
        console.log(subsFilter)
        window.generateTile(subsFilter)
    }

    //https://www.googleapis.com/youtube/v3/subscriptions?part=snippet&mine=true&maxResults=50&key=AIzaSyBGY_rNbCNhASTMeTeNfBRryQviLSTkd10&access_token=

    /*loadYoutubeApi() {
        const script = document.createElement("script");
        script.src = "https://apis.google.com/js/client.js";
    
        script.onload = () => {
            window.gapi.load('auth2', function() {
                window.gapi.auth2.init({
                    client_id: CLIENT_ID,
                    scope: SCOPE
                })
            })
            // window.gapi.load('client', () => {
            //     window.gapi.client.setApiKey(API_KEY)
            //     window.gapi.client.setClientId(CLIENT_ID)
            //     window.gapi.client.setScope(SCOPE)
            //     window.gapi.client.load('client:auth2', 'v3', () => {
            //         console.log("gapi is ready")
            //         this.setState({ gapiReady: true });
            //     });
            // });
        };
    
        document.body.appendChild(script);
    }*/

    render() {
        //<div id="g-signin2"/>
        return (
            <div id="credmenu" className="Credmenu">
                <Profpic id="pfp"/>
                {this.state.renderButton
                    ?   <div className='HomepageLogin'>
                            <GoogleLogin id="loginbutton"
                            clientId = {CLIENT_ID}
                            buttonText="Login"
                            onSuccess={this.onSignIn}
                            onFailure={this.onSignIn}
                            cookiePolicy={'single_host_origin'}
                            scope = {SCOPE}
                            className="GiveMargin"/>
                            <div className="HomepageDataContainer">
                                <img src={logo} alt='"Who Am I Subbed To?" logo' width="100px"/>
                                <h3><i>Who Am I Subbed To?</i></h3>
                                <p>See the channels that you have been subscribed to on YouTube in a neat way: an image collage grid full of those channels' profile pictures!<br/><br/>Start by logging in using your YouTube account, and with your permission, we will display them right after then! Control how big the tiles are with the slider on the lower left.</p>
                            </div>
                            
                        </div>
                    : <div className="BelowPfp">
                        <a href='/#' style={{fontSize:"large"}}>
                            Subscription list of
                        </a>
                        <b>
                            {this.state.name}
                        </b>
                    </div>}
            </div>
        );
    }
}

export default SignIn;