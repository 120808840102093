import React, {Component} from 'react';
import './BGTile.css';
import {updateVariableTileWidth} from '../slider/Slider.js';


class Tile extends Component {
    constructor(props) {
        super(props);
        this.state = {src: props.src.url};
        //this.state = {src: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z8DwHwAFBQIAX8jx0gAAAABJRU5ErkJggg=="};
    }

    render() { return(
        <img className="Tile" src={this.state.src} alt=""/>
    )}
}

class BGTile extends Component {
    constructor(props) {
        super(props);
        this.state = {content : [], subsData : [], vw : document.documentElement.clientWidth, vh : document.documentElement.clientHeight};
        window.generateTile = this.generate.bind(this);
    }

    updateDims() {
        updateVariableTileWidth();
        this.setState({vw : document.documentElement.clientWidth, vh : document.documentElement.clientHeight});
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDims.bind(this));
    }

    generate(subs) {
        var channelData;
        for (channelData of subs) {
            this.state.content.push(<Tile key={`${channelData.snippet.resourceId.channelId}`} src={channelData.snippet.thumbnails.medium}/>);
        }
        this.setState({subsData : subs})
    }

    render() { return(
        <div className="BackroundContainer">
            {this.state.content}
        </div>
    )}
}

export default BGTile;