import React, {Component} from 'react';
import './Profpic.css'

class Profpic extends Component {
    constructor(props) {
        super(props)
        this.state = {src:"https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"};
        this.updateIm = this.updateIm.bind(this);
    }

    updateIm(imsrc) {
        this.setState({src:imsrc});
    }

    componentDidMount(){
        window.pfpinstance=this;
        console.log(window.pfpinstance);
    }
    
    render() {
        return(
        <img className="Profpic" src={this.state.src} alt=""/>
        )
    }
}

export default Profpic;