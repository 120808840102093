import React, {Component} from 'react';
import './Slider.css';
import '../../App.css';

var rootStyle;
try {
    rootStyle = document.styleSheets[4].cssRules[0].style;    
} catch(err) {
    rootStyle = document.styleSheets[1].cssRules[21].style;
}


// eslint-disable-next-line
export function updateVariableTileWidth() {
    const baseSize_STR = rootStyle.getPropertyValue('--base-tile-size');
    const baseSize = parseInt(baseSize_STR.slice(0,baseSize_STR.length-2));
    const viewportWidth = document.documentElement.clientWidth;
    const numTile = Math.ceil(viewportWidth / baseSize);
    const subtractor = baseSize - (viewportWidth / numTile);
    const variableSize = baseSize - subtractor;
    rootStyle.setProperty('--variable-tile-size', `${variableSize}px`);

    return variableSize;
}
window.forceDebugUpdate = updateVariableTileWidth;

class Slider extends Component {
    constructor(props) {
        super(props);
        this.state = {tileSize: '100px', value: 100};
    }

    //https://stackoverflow.com/questions/36122034/jsx-react-html5-input-slider-doesnt-work
    handleChange (event) {
        window.slider.setState({value: event.target.value})
        rootStyle.setProperty('--base-tile-size', `${window.slider.state.value}px`);
        updateVariableTileWidth();
    }

    componentDidMount() {
        updateVariableTileWidth();
    }


//<div className="SliderRowFiller"/>
    render() {
        window.slider = this;
        return(
        <div className="SliderRow">
            <div className="SliderBox SliderRowItems">
                <div className="BoxFiller"/>
                <input
                    type="range"
                    min="25"
                    max="640"
                    value={`${window.slider.state.value}`}
                    className="slider"
                    id="tileSizeSlider"
                    onChange={this.handleChange.bind(this)}
                    />
                <div className="BoxFiller"/>
            </div>
            
            <div className="BottomTextBox SliderRowItems">
                <div className="BoxFiller"/>
                <a href="https://me.maufirf.com/blog/donate-en/">Donate <span role="img" aria-label="international">🌏</span></a>
                <div className="BoxFiller"/>
            </div>
            <div className="BottomTextBox SliderRowItems">
                <div className="BoxFiller"/>
                <a href="https://me.maufirf.com/blog/donate-id/">Donate <span role="img" aria-label="indonesia">🇮🇩</span></a>
                <div className="BoxFiller"/>
            </div>
            <div className="BottomTextBox SliderRowItems">
                <div className="BoxFiller"/>
                <a href="/privacypolicy.html">Privacy Policy</a>
                <div className="BoxFiller"/>
            </div>
            <div className="BottomTextBox SliderRowItems">
                <div className="BoxFiller"/>
                <a href="/tos.html">Terms of Service</a>
                <div className="BoxFiller"/>
            </div>
        </div>
    )}
}

export default Slider;